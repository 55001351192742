import React from 'react'
import SocialLogin from 'react-social-login'
import { FacebookLoginButton } from "react-social-login-buttons";

class SocialButton extends React.Component<SocialButtonProps> {

    render() {
        return (
            <FacebookLoginButton onClick={this.props.triggerLogin} {...this.props} />
        );
    }
}

interface SocialButtonProps {
    triggerLogin: () => void;
}

export default SocialLogin(SocialButton);