class Message {
    constructor(
        public timestamp: number,
        public human: boolean,
        public content: string,
        public type: "text" | "image",
    ){ 
        if (type === "image"){
            this.content = this.content.replace(" ", "");
        }
    }
}

export default Message;