import User from "../types/User";
import axios from 'axios';
import { BACKEND_URL } from "./auth";
import Message from "../types/Message";


export const getMessageHistory = async (user: User): Promise<Message[] | undefined> => {
    try {
        let res = await axios.get<{records: IMessageHistory[]}>(`${BACKEND_URL}/api/chat_history/`,
            {
                headers: user.getHeader()
            }
        );
        let arr = res.data.records;
        return arr.reverse().map((mhj) => new Message(
            mhj.timestamp,
            mhj.from_human,
            mhj.content,
            mhj.type_of_msg
        ));
    } catch (e) {
        console.log(e);
        return undefined;
    }
}

interface IMessageHistory {
    timestamp: number,
    from_human: boolean,
    content: string,
    type_of_msg: 'text' | 'image',
}


export const sendMessage = async (user: User, content: string): Promise<Message[] | undefined> => {
    try {
        let res = await axios.post<{
            responses: IMessageHistory[]
        }>(`${BACKEND_URL}/api/process_chat/`,
            {
                text: content,
            },
            {
                headers: user.getHeader()
            }
        );

        if (res.status !== 200) {
            throw Error;
        }
        let arr = res.data.responses;
        return arr.map((mhj) => new Message(
            mhj.timestamp,
            mhj.from_human,
            mhj.content,
            mhj.type_of_msg
        ));
    } catch {
        return undefined;
    }
}