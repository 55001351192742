import React, { useState } from 'react';
import './scss/App.css';
//@ts-ignore
import ChatBox from 'react-chat-plugin';
import User from './types/User';
import SocialButton from './components/SocialButton';
import { getMessageHistory, sendMessage } from './netcode/msg';
import { getUserWithFB } from './netcode/auth';
import { Loader } from './components/Loader';
import 'bootstrap/dist/css/bootstrap.css';
import ImageGallery from 'react-image-gallery';

interface IAuthor {
  username: string;
  id: number;
  avatarUrl: string | null;
}

interface IMessage {
  author?: IAuthor;
  text: string;
  timestamp: number;
  type: string;
  hasError?: boolean;
}

const BOT_NAME = "AISS Bot";
const BOT_AVATAR = 'https://image.flaticon.com/icons/svg/2446/2446032.svg';

const BOT_AUTHOR = {
  username: BOT_NAME,
  id: 0,
  avatarUrl: BOT_AVATAR,
};

let HUMAN_AUTHOR: IAuthor;

function App() {
  let [messages, setMessages] = useState<IMessage[]>([]);
  let [user, setUser] = useState<User>();
  let [isTyping, setTyping] = useState<boolean>(false);
  let [disabled, setDisabled] = useState<boolean>(false);
  let [isLoading, setLoading] = useState<boolean>(false);

  if (user === undefined) {
    // user has not signed in yet
    // show facebook login button

    return <>
      {
        isLoading ? <div className="loading-wrapper"> <Loader /> </div> : null
      }
      <div className={`login-button-wrapper ${isLoading ? "d-none" : ""}`} >
        <div style={{ width: '100%', textAlign: 'center'}}>
          <img alt="logo" className="logo" src="https://static.wixstatic.com/media/bdd04d_29724186981441f79b5978b24867675e~mv2.png/v1/fill/w_236,h_105,al_c,q_85,usm_0.66_1.00_0.01/Screen%20Shot%202019-06-30%20at%202_32_50%20PM.webp" />
          AISS v0.1a
        </div>
        <SocialButton
          provider='facebook'
          appId='502502060253468'
          onLoginSuccess={async (obj) => {
            setLoading(true);
            let user = await getUserWithFB(obj.token.accessToken);
            // get past chat history
            if (user) {
              let messages = await getMessageHistory(user);
              console.log(messages);
              HUMAN_AUTHOR = {
                username: user.name,
                id: 1,
                avatarUrl: 'https://image.flaticon.com/icons/svg/2446/2446032.svg',
              };
              if (messages) {
                setMessages(messages.map<IMessage>((msg) => {
                  switch (msg.type) {
                    case "image":
                      return {
                        author: msg.human ? HUMAN_AUTHOR : BOT_AUTHOR,
                        text: msg.content,
                        timestamp: msg.timestamp,
                        type: "image"
                      }
                    default:
                      return {
                        author: msg.human ? HUMAN_AUTHOR : BOT_AUTHOR,
                        text: msg.content,
                        timestamp: msg.timestamp,
                        type: msg.type
                      }

                  }
                }));
              } else {
                setMessages([{
                  text: `Say hi to ${BOT_NAME}!`,
                  timestamp: new Date().getTime(),
                  type: 'notification'
                }])
              }
              setUser(user);
            }
            // TODO: show error here
            setLoading(false);
          }}
          onLoginFailure={() => {
            // TODO: show error here
            setLoading(false);
          }}
        />
      </div>
    </>
  }
  else {
    const handleOnSendMessage = (message: string) => {
      setDisabled(true);
      let timer = setTimeout(() => setTyping(true), Math.random() * 1000); // show typing indicator after 0 - 1s

      let sent: IMessage =
      {
        author: HUMAN_AUTHOR,
        text: message,
        timestamp: +new Date(),
        type: 'text',
      };
      let newarr = messages.concat(sent);
      setMessages(newarr);
      sendMessage(user!, message).then((msgs) => {
        clearTimeout(timer);
        if (msgs === undefined) {
          // TODO: add error message here
          newarr[messages.length - 1].hasError = true;
          setMessages(newarr);
        } else {
          setMessages(newarr.concat(
            msgs.map((msg) =>
            ({
              author: BOT_AUTHOR,
              text: msg.content,
              timestamp: msg.timestamp,
              type: 'text', // TODO: only text for now
            })
            ))
          );
        }
        setTyping(false);
        setDisabled(false);
      })
    }

    let msgWithPhotos = messages.filter((msg) => msg.type === "image");
    let transformedMessages = messages.map((msg) => {
      let msgc = { ...msg }
      if (msgc.type === "image") {
        msgc.text = "Photo";
        msgc.type = "text";
      }
      return msgc;
    })
    console.info(msgWithPhotos);
    return (
      <div className="App">
        You are {user.name}.
        <div>
          <ChatBox
            messages={transformedMessages}
            userId={1}
            onSendMessage={handleOnSendMessage}
            width={'100%'}
            height={'60vh'}
            showTypingIndicator={isTyping}
            activeAuthor={BOT_AUTHOR}
            disableInput={disabled}
          />
          {
            isTyping &&
            <div>
              <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
              {BOT_NAME} is typing...
            </div>
          }
          {
            msgWithPhotos.length > 0 &&
            <div className="gallery">
              <ImageGallery
                showPlayButton={false}
                items={
                  msgWithPhotos.map((msg) => ({
                    original: msg.text
                  }))
                }
              />
            </div>
          }
        </div>
      </div>
    );
  }
}

export default App;
