import axios from 'axios';
import User from '../types/User';

export const BACKEND_URL = process.env.REACT_APP_SERVER_URL;
export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
export const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET;

export const getUserWithFB = async (token: string): Promise<User | undefined> => {
    try{
        console.log(token);
        let res = await axios.post(`${BACKEND_URL}/auth/convert-token/`, {
            "grant_type": "convert_token",
            "client_id": CLIENT_ID,
            "client_secret": CLIENT_SECRET,
            "backend": "facebook",
            "token": token,
        });
        console.log(res.data);
        let username = "test";
        let email = "testemail";
        let django_token = res.data.access_token;

        return new User(
            username,
            email,
            django_token,
        )
    } catch {
        console.log("error");
        return undefined;
    }
}