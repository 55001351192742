import '../scss/loader.css';
import React from 'react';

/**
 * Custom Loader that look likes a droplet
 */
export const Loader = (props: LoadingProps) => (
    <div className="lds-grid"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>

);

interface LoadingProps {
    wrap?: boolean;
}

/**
 * has a loader displayed if loading set to true

const withLoading = <P extends object>(Component: React.ComponentType<P>) =>
    class WithLoading extends React.Component<P & WithLoadingProps> {
        render() {
            const { loading, ...props } = this.props;
            return loading ? <Loader /> : <Component {...props as P} />;
        }
    }; */
