
class User {
    constructor(
        public name: string,
        public email: string,
        public accessToken: string,
    ){

    }

    public getHeader = () => {
        return {
            Authorization: `Bearer ${this.accessToken}`
        }
    }
}

export default User;